import { Component, OnInit } from '@angular/core';
import servicios from '../../../model/servicios';
import { Servicio } from 'src/app/model/servicio';
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  
  servicios : Servicio[]= servicios.filter(x=>x.slider);//: Servicio[] = [];
  
  constructor() { 
  }
  
  
  ngOnInit() {
  }

}
