import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shell-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  contacto = "footer";
  constructor() { }

  ngOnInit() {
  }

}
