import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShellModule } from './shell/shell.module';
import { PrincipalComponent } from './view/principal/principal.component';
import { NotFoundComponent } from './view/not-found/not-found.component';
import { SliderComponent } from './view/principal/slider/slider.component';
import { ServiciosComponent } from './view/principal/servicios/servicios.component';
import { ContactFormModule } from './components/contact-form/contact-form.module';

import { NgwWowModule } from 'ngx-wow';

import {HttpClientModule} from '@angular/common/http';
import { CustomersComponent } from './view/customers/customers.component';


@NgModule({
  declarations: [
    AppComponent,
    PrincipalComponent,
    NotFoundComponent, 
    SliderComponent, 
    ServiciosComponent, CustomersComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShellModule,
    ContactFormModule,
    NgwWowModule,
    HttpClientModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
