import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PrincipalComponent } from "./view/principal/principal.component";
import { NotFoundComponent } from "./view/not-found/not-found.component";
import { CustomersComponent } from "./view/customers/customers.component";

const routes: Routes = [
  {
    path: "",
    component: PrincipalComponent,
    pathMatch: "full",
  },
  {
    path: "LaEmpresa",
    loadChildren: () =>
      import("./view/la-empresa/la-empresa.module").then(
        (m) => m.LaEmpresaModule
      ),
  },
  {
    path: "Servicios",
    loadChildren: () =>
      import("./view/servicios/servicios.module").then(
        (m) => m.ServiciosModule
      ),
  },
  {
    path: "rrhh",
    loadChildren: () =>
      import("./view/rrhh/rrhh.module").then((m) => m.RrhhModule),
  },
  // {
  //   path: "Clientes",
  //   component : CustomersComponent,
  // //   loadChildren: () =>
  // //     import("./view/customers/customers.module").then((m) => m.CustomersModule),
  //  },
  {
    path: "Contacto",
    loadChildren: () =>
      import("./view/contact/contact.module").then((m) => m.ContactModule),
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
