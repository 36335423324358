import { Component, OnInit } from "@angular/core";
import Consulta from "src/app/model/consulta";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { ContactService } from "src/app/services/contact.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "contactForm",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.css"],
})
export class ContactFormComponent implements OnInit {
  a: prueba;
  qrecaptcha: any[];
  //submited: boolean;
  public consulta: Consulta;

  contactFormGroup: FormGroup;
  submitted = false;

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private serviceContact: ContactService,
    private formBuilder: FormBuilder
  ) {
    this.consulta = <Consulta>{};
  }

  ngOnInit() {
    this.contactFormGroup = this.formBuilder.group({
      name: ["", Validators.required],
      phone: ["", Validators.required],
      mail: ["", [Validators.required, Validators.email]],
      consult: ["", Validators.required],
      area: ["", Validators.required],
    });

    this.contactFormGroup.controls["area"].setValue("Comercial");
  }

  public Enviar(): void {
    // stop here if form is invalid
    if (this.contactFormGroup.invalid) {
      console.log("fallo la validacion del formulario" + this.submitted);
      return;
    }
    this.submitted = true;

    let formContent: controlesForm = this.contactFormGroup.value;
    /*
    {
      "from": "postMain Test",
      "apiKey" : "p#vYfOl!!Aj$&G8onB5lDCaz",
      "controller": "mail",
      "content" : {
              "from": "sitioswebalzueta@gmail.com", 
              "nameFrom": "LIMPIEZA PRIMUS",
              "user" : "sitioswebalzueta@gmail.com",
              "pass" :"MysqlQuery21",
              "port": 587,
              "subject" :"Mail de prueba ",
              "body" :"<h1>hola</h1> <b>mundo</b>",
              "to": ["danielalzueta@gmail.com", "alff21@gmail.com"]
          }
      }*/
    let body: string = `Este Mail es enviado desde la pagina web de la empresa con los datos de la
      persona que envia la consulta , NO TIENEN QUE RESPONDER A ESTE MAIL, ya que
      si responden a este mail, la respuesta estarian enviandola a la pagina.
        La respuesta debe ser enviada al mail que coloco la persona que envio la
      consulta, es el mail que esta en el apartado Mail.</b>
      <br><b>Area: </b> ${formContent.area}
      <br><b>Nombre: </b> ${formContent.name}  
      <br><b>Telefono: </b>  ${formContent.phone}  
      <br><b>Mail:</b> ${formContent.mail} 
      <br><b>Consulta</b><p>
      ${formContent.consult}</p><br><br><br>Este mail fue enviado desde el sitio web www.LimpiezaPrimus.com.ar <br><b>Que tenga un bien dia :) - `;
    let area = `Limpieza Primus Web : ${formContent.area}`;

    let envio = {
      from: "limpiezaprimus",
      apiKey: "p#vYfOl!!Aj$&G8onB5lDCaz",
      controller: "mail",
      content: {
        from: "sitioswebalzueta@gmail.com",
        nameFrom: area,
        user: "sitioswebalzueta@gmail.com",
        pass: "MysqlQuery21",
        port: 587,
        subject: "Contacto desde WWW.LIMPIEZAPRIMUS.COM.AR",
        body: body,
        to: [
          "danielalzueta@gmail.com",
          "primuslimpiezaespecializada@gmail.com",
        ],
      },
    };

    let suscription = this.serviceContact.send(envio).subscribe((res) => {
      console.log(res);
      this.a = res as prueba;

      suscription.unsubscribe();
    });
  }

  handleToken(token) {
    alert(
      "Se estan realizando modificaciones en el sitio, por el momento su consulta no sera enviada. Porfavor enviar su consulta al mail de contacto"
    );

    this.recibir();
  }

  recibir() {
    let cont = this.serviceContact
      .getMesages()
      .subscribe((Res) => console.log(Res));
    console.log(cont);
  }
}
interface prueba {
  result: boolean;
}

interface controlesForm {
  name: string;
  phone: string;
  mail: string;
  consult: string;
  area: string;
}
