import { Component } from '@angular/core';
import {NgwWowService} from 'ngx-wow';
import { Router, NavigationEnd } from '@angular/router';
import {filter} from 'rxjs/operators';

declare var gtag;

@Component({
  selector: 'LimpiezaPrimus',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  constructor(private servicio: NgwWowService , private route: Router){
    this.servicio.init();
    const navEndEvents = route.events.pipe(
      filter(event=> event instanceof NavigationEnd),
    );

    navEndEvents.subscribe((event: NavigationEnd)=> {
      gtag('config', 'UA-50148035-1', {
        'page_path': event.urlAfterRedirects
      });
    })
  }
}
