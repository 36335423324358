import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShellRoutingModule } from './shell-routing.module';
import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { NavigatorMenuComponent } from '../components/navigator-menu/navigator-menu.component';
import { ContactModule } from '../components/contact/contact.module';



@NgModule({
  declarations: [ShellComponent, HeaderComponent, FooterComponent,NavigatorMenuComponent],
  imports: [
    CommonModule,
    ShellRoutingModule,
    ContactModule
  ],
  exports:[ShellComponent]
})
export class ShellModule { }
