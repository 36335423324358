import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from './contact-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule } from 'ng-recaptcha';
import { ContactService } from 'src/app/services/contact.service';


@NgModule({
  declarations: [ContactFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    RecaptchaV3Module, 
    RecaptchaFormsModule,
    ReactiveFormsModule
  ],
  exports:[ContactFormComponent],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lfh5KMUAAAAAM7qXUlL7VRYsVqZN4qUgYB45-oW'},ContactService
  ]
})
export class ContactFormModule { }
