import { Component, OnInit, Input } from "@angular/core";

import contactos from "../../model/contacts";
import contact from "src/app/model/contact";

@Component({
  selector: "infoContact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
})
export class ContactComponent implements OnInit {
  @Input() donde: string;
  contactos: contact[] = [];
  domicilio: contact[];
  telefono: contact[];
  celular: contact[];
  whatsapp: contact[];
  mail: contact[];
  show: boolean;

  constructor() {
    //console.log("en el constructor" + this.donde);
    this.contactos = contactos;
    // console.log(this.contactos);
    this.domicilio = this.contactos.filter((x) => x.type == "domicilio");
    this.telefono = this.contactos.filter((x) => x.type == "telefono");
    this.celular = this.contactos.filter((x) => x.type == "celular");
    this.mail = this.contactos.filter((x) => x.type == "mail");
    this.whatsapp = this.contactos.filter((x) => x.type == "whatsapp");
  }

  ngOnInit() {
    // console.log(this.donde);
    if (this.donde == "contacto") {
      this.show = true;
    } else {
      this.show = false;
    }
  }
}
