import { Component, OnInit } from '@angular/core';
import { Servicio } from 'src/app/model/servicio';
import servicios from '../../../model/servicios';
@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  servicios: Servicio[] = servicios.filter(x=>x.imgPantallaPrincipal!=""); 
  constructor() { }

  ngOnInit() {
  }

}
