let links = [
    {
      title: "inicio",
      path:"/",
      servicios: []
    },
    {
      title: "la empresa",
      path:"/LaEmpresa",
      servicios: []
    },
    // {
    //   title: "clientes",
    //   path:"/Clientes",
    //   servicios: []
    // }
    // ,
    {
      title: "servicios",
      path:"/Servicios",
      servicios: [
        {
          title: "limpieza de oficinas y comercios",
          path: "servicios#oficinas"
        },
        {
          title: "limpieza hotelera",
          path: "d"
        },
        {
          title: "alfombras y tapizados",
          path: ""
        },
        {
          title: "espacios verdes",
          path: ""
        },
        {
          title: "finales de obra",
          path: ""
        },
        {
          title: "higiene hospitalaria",
          path: ""
        },
        {
          title: "limpieza en altura",
          path: ""
        },
        {
          title: "medios de transporte",
          path: ""
        }
        
      ]
    },
    // ,
    // {
    //   title: "rrhh",
    //   path:"/rrhh",
    //   servicios: []
    // },
    {
      title: "contacto",
      path:"/Contacto",
      servicios: []
    }
  ];
  export default links;