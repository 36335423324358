import { Component, OnInit, Input } from '@angular/core';
import links from './menuLinks';
@Component({
  selector: 'navigatorBar',
  templateUrl: './navigator-menu.component.html',
  styleUrls: ['./navigator-menu.component.css']
})
export class NavigatorMenuComponent implements OnInit {
  @Input() type :string;
  
  menu = links;
  menuBar:boolean ;
  constructor() { 
    
  }

  ngOnInit() {
    if(this.type =="navBarSmall"){
      this.menuBar=true;
    }
  }


}
