import contact from "./contact";

let contacts: contact[] = [];

contacts.push({
  id: 1,
  type: "telefono",
  content: "(381) - 4845549",
});
contacts.push({
  id: 2,
  type: "celular",
  content: "(381) - 154755156",
});
contacts.push({
  id: 3,
  type: "celular",
  content: "(381) - 155490479",
});
contacts.push({
  id: 4,
  type: "domicilio",
  content: "Av. Mitre 539",
});
contacts.push({
  id: 5,
  type: "whatsapp",
  content: "(381) 5298038",
});
contacts.push({
  id: 6,
  type: "mail",
  content: "Gerencia@limpiezaprimus.com.ar",
});
contacts.push({
  id: 7,
  type: "mail",
  content: "Ventas@limpiezaprimus.com.ar",
});
contacts.push({
  id: 8,
  type: "rrhh",
  content: "rrhh@limpiezaprimus.com.ar",
});

export default contacts;
