import { Servicio } from "./servicio";

let servicios : Servicio[] = [];

servicios.push({
    id:1,
    titleSlider: "Limpieza Hotelera",
    imgSlider: "assets/img/Slide1.webp", 
    descriptionSlider:"Contamos con una división especial dentro de nuestra empresa, MUCAMAS DE HOTEL capacitadas para cumplir con pasos y procedimientos reglamentados para realizar dicha actividad.",
    descriptionServicios:[`Contamos con una división especial dentro de nuestra empresa, MUCAMAS DE HOTEL capacitadas para cumplir con pasos y procedimientos reglamentados para realizar dicha actividad, aplicando tipos, formas y principios que hacen posible resultados de excelencia tanto en HABITACIONES como en AREAS PUBLICAS.`],
    titlePantallaPrincipal: "Limpieza Hotelera",
    descriptionPantallaPrincipal: "",
    imgPantallaPrincipal: "",
    imgServicios:"assets/img/miniHot.jpg",
    slider:true,
    titleServicios:"LIMPIEZA HOTELERA",
    imgTitleServicios :"assets/img/minHotelera.jpg"
});
servicios.push({
    id:2,
    titleSlider: "Limpieza de Oficinas y Comercios",
    imgSlider: "assets/img/Slide2.webp", 
    descriptionSlider:"Limpieza de pisos, aspirado de alfombras , parquet, Limpieza de sanitarios, espejos, mamparas, ceniceros, luminarias, etc.",
    
    descriptionServicios:[
    "Aspirado de alfombras",
    "Limpieza de todo tipo de pisos, parque, granito, alisado, porcelanato, etc.",
    "Limpieza de sanitarios, espejos, mamparas, luminarias.",
    "Vidrios interiores y exteriores. Techos, escaleras",
    "Limpieza diaria de mobiliario, telefonía y computacion",
    "Odorización de ambientes.",
    "Tratamiento especial de despachos, salas de juntas, gerencias y directorios."],
    titlePantallaPrincipal: "Limpieza de Oficinas",
    descriptionPantallaPrincipal: "Limpieza de pisos, aspirado de alfombras o parquet. Limpieza de sanitarios, espejos, mamparas, ceniceros, luminarias. Vidrios interiores y exteriores. Techos, escaleras. Limpieza diaria de mobiliario, telefonía y computador. Odorización de ambientes. Tratamiento especial de despachos, salas de juntas, Gerencias y Directorios.",
    imgPantallaPrincipal: "assets/img/limpiezadeOficina.webp",
    imgServicios:"assets/img/Minoficinas.jpg",
    slider:true,
    titleServicios:"LIMPIEZA DE COMERCIOS Y OFICINAS",
    imgTitleServicios :"assets/img/servOficinas.jpg"
});

servicios.push({
    id:3,
    titleSlider: "Vidrios en Altura",
    imgSlider: "assets/img/Slide3.webp", 
    descriptionSlider:"Nuestra empresa se especializa en la prestación de servicios de limpieza de vidrios en altura.",
    descriptionServicios:["Nuestra Empresa se especializa en la prestación de servicios de limpieza de vidrios en altura. Nuestro equipo de trabajo cuenta con entrenamiento adecuado en este tipo de servicios, tanto en el area operativa como en la seguridad.",
    "",
    "Completamos nuestra ingeniería de servicios con procedimientos claros y específicos que se diseñan en función del trabajo a realizar."],
    titlePantallaPrincipal: "Vidrios en Altura",
    descriptionPantallaPrincipal: "",
    imgPantallaPrincipal: "",
    imgServicios:"assets/img/vidrios.jpg",
    slider:true,
    titleServicios:"Limpieza de Vidrios en Altura",
    imgTitleServicios :"assets/img/minAltura.jpg"
});

servicios.push({
    id:4,
    titleSlider: "Alfombras y Tapizados",
    imgSlider: "assets/img/Slide4.webp", 
    descriptionSlider:"Limpiamos todo tipo de Alfombras y Tapizados con personal capacitado y maquinarias especificas.",
    descriptionServicios:[`Limpiamos todo tipo de Alfombras y Tapizados con personal capacitado y maquinarias especificas, logrando extraer la suciedad desde la base de la alfombra y removiendo manchas. Ponemos especial Cuidado de no estropear paredes, pisos o muebles. Los productos utilizados contienen limpiadores y desinfectantes ( acaricidas, pulguicidas, antipolillas y bactericidas).`],
    titlePantallaPrincipal: "Limpieza de Alfombras",
    descriptionPantallaPrincipal: "Limpiamos todo tipo de Alfombras y Tapizados con personal capacitado y maquinarias especificas, logrando extraer la suciedad desde la base de la alfombra, removiendo suciedad y manchas. Ponemos especial Cuidado de no estropear paredes, pisos o muebles. Los productos utilizados contienen limpiadores y desinfectantes ( acaricidas, pulguicidas, antipolillas y bactericidas)",
    imgPantallaPrincipal: "assets/img/limpiezadeAlfombra.jpg",
    imgServicios:"assets/img/limpiezadeAlfombra.jpg",
    slider:true,
    titleServicios:"Limpieza de Alfombras y Tapizados",
    imgTitleServicios :"assets/img/minAlfombra.jpg"
});

servicios.push({
    id:5,
    titleSlider: "Finales de Obra",
    imgSlider: "assets/img/Slide5.webp", 
    descriptionSlider:"La dificil tarea de lograr una limpieza de excelencia debido a la superposición de rubros en el momento de concluir una obra, es la especialidad de nuestra empresa",
    descriptionServicios:["La difícil tarea de lograr una limpieza de excelencia debido a la superposición de rubros en el momento de concluir una obra, es la especialidad de nuestra empresa. Coordinar, insistir y obtener resultados óptimos requiere de experiencia y capacitación.",
    "",
    "Estas características nos destacan junto al cumplimiento de plazos pautados, sinceridad, competencia y responsabilidad."],
    titlePantallaPrincipal: "Final de Obra",
    descriptionPantallaPrincipal: "La difícil tarea de lograr una limpieza de excelencia debido a la superposición de rubros en el momento de concluir una obra, es la especialidad de nuestra empresa. Coordinar, insistir y obtener resultados óptimos requiere de experiencia y capacitación. Estas características nos destacan junto al cumplimiento de plazos pautados, la sinceridad, competencia y responsabilidad.",
    imgPantallaPrincipal:"assets/img/limpiezadeObra.webp",
    imgServicios:"assets/img/piso.jpg",
    slider:true,
    titleServicios:"Limpieza Final de Obra",
    imgTitleServicios :"assets/img/minObraF.jpg"
});

servicios.push({
    id:6,
    titleSlider: "Limpieza de Espacios Verdes",
    imgSlider: "assets/img/Slide6.webp", 
    descriptionSlider:"",
    descriptionServicios:[""],
    titlePantallaPrincipal: "",
    descriptionPantallaPrincipal: "",
    imgPantallaPrincipal: "",
    imgServicios:"assets/img/servVerdes.jpg",
    slider:true,
    titleServicios:"Espacios Verdes",
    imgTitleServicios :"assets/img/minVerdes.jpg"
});

servicios.push({
    id:6,
    titleSlider: "Medios de Transporte",
    imgSlider: "assets/img/Slide7.webp", 
    descriptionSlider:"",
    descriptionServicios:[""],
    titlePantallaPrincipal: "",
    descriptionPantallaPrincipal: "",
    imgPantallaPrincipal: "",
    imgServicios:"assets/img/servColec.jpg",
    slider:true,
    titleServicios:"Limpieza de Medios de Transporte",
    imgTitleServicios :"assets/img/minColectivo.jpg"
});

servicios.push({
    id:6,
    titleSlider: "Limpieza Hospitalaria",
    imgSlider: "assets/img/Slide7.webp", 
    descriptionSlider:"",
    descriptionServicios:[""],
    titlePantallaPrincipal: "",
    descriptionPantallaPrincipal: "",
    imgPantallaPrincipal: "",
    imgServicios:"assets/img/limpiezahospitalaria3.jpg",
    slider:false,
    titleServicios:"Limpieza Hospitalaria",
    imgTitleServicios :"assets/img/serHospi.jpg"
});

export default servicios; 