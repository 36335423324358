import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable(
//  {
//  providedIn: 'root'
//}
)
export class ContactService {
  private url : string = "https://api.limpiezaprimus.com.ar/";
  //private url : string = "https://phptest-270300.appspot.com/";
  //private url : string = "http://localhost:8080";
  
  

  constructor(private http: HttpClient) { }

  public send(content){
    console.log(content);
    return this.http.post(this.url, content);
  }


  public getMesages():Observable<string[]>{
    return this.http.get<string[]>(this.url);
  }

  public log(message: string){
    console.log(message);
  }
}
